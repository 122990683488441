import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import SignIn from "./Screens/SignIn";
import Dashboard from "./Screens/Dashboard";
import Students from "./Screens/Students";
import StudentProfile from "./Screens/StudentProfile";
import RegisterationForm from "./Screens/RegisterationForm";
import { Routes, Route } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import Navbar from "./Components/Navbar";
import Reports from "./Screens/Reports";
import Education from "./Screens/Education";

function App() {
  return (
    <>
        <Row style={{padding:0, margin:0}}>
          <Col>
            <Routes>
              <Route path="/" element={<SignIn />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/student" element={<Students />} />
              <Route path="/studentprofile" element={<StudentProfile />} />
              <Route path="/newregisteration" element={<RegisterationForm />} />
              <Route path="/education" element={<Education />} />
              <Route path="/reports" element={<Reports />} />
            </Routes>
          </Col>
        </Row>
    </>
  );
}

export default App;
