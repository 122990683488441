import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";

export default function MotherInfo({formData, updateFormData}) {
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    updateFormData({ ...formData, [name]: value });
  };
  return (
    <Row style={{ backgroundColor: "white", padding: 10 }}>
      <Row
        style={{
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
          borderBottom: "1px solid lightgrey",
          padding: 5,
          marginBottom: 10,
        }}
      >
        <h5>Mother Information</h5>
      </Row>
      <Row style={{ padding: 2 }}>
        <Col>
          <Form.Group controlId="">
            <Form.Label>Mother Name</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="motherName"
                value={formData.motherName}
              onChange={handleInputChange}
              placeholder="Mother Name"
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="">
            <Form.Label>CNIC No.</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="motherCnicNo"
                value={formData.motherCnicNo}
              onChange={handleInputChange}
              placeholder="CNIC No."
            />
          </Form.Group>
        </Col>
      </Row>
      <Row style={{ padding: 2 }}>
        <Col>
          <Form.Group controlId="">
            <Form.Label>Is she Alive?</Form.Label>
            <Form.Select aria-label="Default select example" size="sm"
                         name="motherIsAlive"
                         value={formData.motherIsAlive}
                         onChange={handleInputChange}
            >
              <option>Select Option</option>
              <option value="1">Yes</option>
              <option value="2">No</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="">
            <Form.Label>Date of Death</Form.Label>
            <Form.Control
              size="sm"
              type="date"
              name="motherDateOfDeath"
                value={formData.motherDateOfDeath}
              onChange={handleInputChange}
              placeholder="Date of Death"
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="">
            <Form.Label>Reason of Death</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="motherReasonOfDeath"
                value={formData.motherReasonOfDeath}
              onChange={handleInputChange}
              placeholder="Reason of Death"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row style={{ padding: 2 }}>
        <Col>
          <Form.Group controlId="">
            <Form.Label>Marital Status</Form.Label>
            <Form.Select aria-label="Default select example" size="sm"
                         name="motherMaritalStatus"
                         value={formData.motherMaritalStatus}
                         onChange={handleInputChange}
            >
              <option>Select Option</option>
              <option value="1">Widow</option>
              <option value="2">Divorced</option>
              <option value="2">Second Marriage</option>
              <option value="2">Missing Husband</option>
              <option value="2">Other</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="">
            <Form.Label>Skill</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="motherSkill"
                value={formData.motherSkill}
              onChange={handleInputChange}
              placeholder="Skill"
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="">
            <Form.Label>Education</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="motherEducation"
                value={formData.motherEducation}
              onChange={handleInputChange}
              placeholder="Education"
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="">
            <Form.Label>Occupation</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="motherOccupation"
                value={formData.motherOccupation}
              onChange={handleInputChange}
              placeholder="Occupation"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row style={{ padding: 2 }}>
        <Col xs={8}>
          <Form.Group controlId="">
            <Form.Label>Institution Name</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="motherInstituteName"
                value={formData.motherInstituteName}
              onChange={handleInputChange}
              placeholder="Institution Name"
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="">
            <Form.Label>Position</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="motherDesignation"
                value={formData.motherDesignation}
              onChange={handleInputChange}
              placeholder="Position"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row style={{ marginBottom: 10 }}>
        <Row as={Col}>
          <Col
            className="d-flex"
            style={{
              textAlign: "center",
              marginRight: 4,
              justifyContent: "center",
              alignItems: "center",
              paddingTop: 20,
            }}
            xs={5}
          >
            <p>Children Below Age 12:</p>
          </Col>
          <Col>
            <Form.Group controlId="">
              <Form.Label>Sons</Form.Label>
              <Form.Control
                size="sm"
                type="text"
                name="sonBelowTwelveCount"
                  value={formData.sonBelowTwelveCount}
                onChange={handleInputChange}
                placeholder="Sons"
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="">
              <Form.Label>Daughters</Form.Label>
              <Form.Control
                size="sm"
                type="text"
                name="daughterBelowTwelveCount"
                  value={formData.daughterBelowTwelveCount}
                onChange={handleInputChange}
                placeholder="Daughters"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row as={Col}>
          <Col
            className="d-flex"
            style={{
              textAlign: "center",
              marginRight: 4,
              justifyContent: "center",
              alignItems: "center",
              paddingTop: 20,
            }}
            xs={5}
          >
            <p>Children Above Age 12:</p>
          </Col>
          <Col>
            <Form.Group controlId="">
              <Form.Label>Sons</Form.Label>
              <Form.Control
                size="sm"
                type="text"
                name="sonAboveTwelveCount"
                  value={formData.sonAboveTwelveCount}
                onChange={handleInputChange}
                placeholder="Sons"
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="">
              <Form.Label>Daughters</Form.Label>
              <Form.Control
                size="sm"
                type="text"
                name="daughterAboveTwelveCount"
                value={formData.daughterAboveTwelveCount}
                onChange={handleInputChange}
                placeholder="Daughters"
              />
            </Form.Group>
          </Col>
        </Row>
      </Row>
      <Row style={{ padding: 2 }}>
        <Col>
          <Form.Group controlId="">
            <Form.Label>Monthly Income</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="motherMonthlyIncome"
                value={formData.motherMonthlyIncome}
              onChange={handleInputChange}
              placeholder="Monthly Income"
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="">
            <Form.Label>House Size</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="motherHouseSize"
                value={formData.motherHouseSize}
              onChange={handleInputChange}
              placeholder="House Size"
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="">
            <Form.Label>House Type</Form.Label>
            <Form.Select aria-label="Default select example" size="sm"
                         name="motherIsHouseOwned"
                         value={formData.motherIsHouseOwned}
                         onChange={handleInputChange}
            >
              <option>Select Option</option>
              <option value="1">Rented</option>
              <option value="2">Owned</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="">
            <Form.Label>Source of Income</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="motherIncomeSource"
                value={formData.motherIncomeSource}
              onChange={handleInputChange}
              placeholder="Source of Income"
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="">
            <Form.Label>Mobile No.</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="motherMobileNo"
                value={formData.motherMobileNo}
              onChange={handleInputChange}
              placeholder="Mobile No."
            />
          </Form.Group>
        </Col>
      </Row>
    </Row>
  );
}
