import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Navbar from "../Components/Navbar";
import { Bell, PersonCircle } from "react-bootstrap-icons";
import EducationChart from "../Components/EducationChart";

function Students() {
  return (
    <Container style={{ backgroundColor: "#f3f3f3" }} fluid>
      <Row>
        <Navbar />
        <Col style={{ padding: 20, paddingLeft: 30, paddingRight: 30 }}>
          <Row>
            <Col>
              <Row>
                <Col>
                  <p
                    style={{
                      marginBottom: -4,
                      fontSize: 14,
                      fontWeight: "400",
                    }}
                  >
                    Dashboard / Reports
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p style={{ fontSize: 24, fontWeight: "600" }}>KPI Reports</p>
                </Col>
              </Row>
            </Col>
            <Col xs={6}>
              <Row className="justify-content-end">
                <Col
                  xs={6}
                  style={{
                    backgroundColor: "white",
                    borderRadius: 100,
                    border: "1px solid",
                    borderColor: "#e5e5e5",
                  }}
                >
                  <Row style={{ paddingTop: 5, paddingBottom: 5 }}>
                    <Col xs={6}>
                      <Form.Group className="w-100">
                        <Form.Control
                          style={{
                            borderRadius: 100,
                            backgroundColor: "#f3f3f3",
                          }}
                          placeholder="search"
                        />
                      </Form.Group>
                    </Col>
                    <Col
                      xs={2}
                      className="d-flex align-items-center justify-content-center"
                    >
                      <Bell />
                    </Col>
                    <Col
                      xs={2}
                      className="d-flex align-items-center justify-content-center"
                    >
                      <Bell />
                    </Col>
                    <Col
                      xs={2}
                      className="d-flex align-items-center justify-content-center"
                    >
                      <PersonCircle size="25px" />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row
            style={{ backgroundColor: "white", borderRadius: 10, padding: 10 }}
          >
            <Row
              style={{
                textAlign: "center",
                borderBottom: "1px solid lightgrey",
              }}
            >
              <h6>Academic Results Comaprison</h6>
            </Row>
            <Col
              style={{ borderRight: "1px solid lightgrey", marginTop: 5 }}
              xs={2}
            >
              <Row style={{ padding: 10 }}>
                <Form.Select size="sm" aria-label="Default select example">
                  <option>Select Campus</option>
                  <option value="1">MCH LHR</option>
                  <option value="2">Kashana LHR</option>
                </Form.Select>
              </Row>
              <Row style={{ padding: 8 }}>
                <Form.Group as={Row} className="d-flex">
                  <Form.Label>Month & Year</Form.Label>
                  <Form.Control type="month" placeholder="Month & Year" />
                </Form.Group>
              </Row>
              <Row style={{ padding: 10 }}>
                <Button>Results</Button>
              </Row>
            </Col>
            <Col
              style={{
                borderRight: "1px solid lightgrey",
                marginTop: 5,
                textAlign: "center",
                // padding: 10,
              }}
              xs={7}
            >
              <EducationChart />
            </Col>
            <Col
              style={{
                borderRight: "1px solid lightgrey",
                marginTop: 5,
                textAlign: "left",
                padding: 10,
              }}
              xs={3}
            >
              <h6 style={{ textAlign: "center" }}>Details</h6>
              <Row style={{ margin: 0 }}>
                <p>
                  <b>Campus Name:</b> MCH LHR
                </p>
                <p>
                  <b>Strength:</b> 63
                </p>
                <p>
                  <b>Month:</b> 2024
                </p>
                <p>
                  <b>Year:</b> MCH LHR
                </p>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default Students;
