import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";

export default function ChildInfo({formData, updateFormData}) {

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    updateFormData({ ...formData, [name]: value });
  };

  return (
    <Row style={{ backgroundColor: "white", padding: 10 }}>
      <Row
        style={{
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
          borderBottom: "1px solid lightgrey",
          padding: 5,
          marginBottom: 10,
        }}
      >
        <h5>Child Information</h5>
      </Row>
      <Row style={{ padding: 2 }}>
        <Col xs={8}>
          <Form.Group controlId="">
            <Form.Label>Student Name</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="studentName"
              value={formData.studentName}
              onChange={handleInputChange}
              placeholder="Student Name"
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="">
            <Form.Label>Gender</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="studentGender"
                value={formData.studentGender}
              onChange={handleInputChange}
              placeholder="Gender"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row style={{ padding: 2 }}>
        <Col>
          <Form.Group controlId="">
            <Form.Label>Date of Birth</Form.Label>
            <Form.Control
              size="sm"
              type="date"
              name="studentBirthDate"
                value={formData.studentBirthDate}
              onChange={handleInputChange}
              placeholder="Date of Admission"
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="">
            <Form.Label>Place of Birth</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="studentBirthPlace"
                value={formData.studentBirthPlace}
              onChange={handleInputChange}
              placeholder="Place of Birth"
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="">
            <Form.Label>Age</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="studentAge"
                value={formData.studentAge}
              onChange={handleInputChange}
              placeholder="Age"
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="">
            <Form.Label>Class</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="studentClass"
                value={formData.studentClass}
              onChange={handleInputChange}
              placeholder="Class"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row style={{ padding: 2 }}>
        <Row as={Col}>
          <Col
            className="d-flex"
            style={{
              textAlign: "center",
              marginRight: 4,
              justifyContent: "center",
              alignItems: "center",
            }}
            xs={2}
          >
            <p>Family:</p>
          </Col>
          <Col>
            <Form.Group controlId="">
              <Form.Label>Brothers</Form.Label>
              <Form.Control
                size="sm"
                type="text"
                name="studentBrothers"
                  value={formData.studentBrothers}
                onChange={handleInputChange}
                placeholder="Brothers"
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="">
              <Form.Label>Sisters</Form.Label>
              <Form.Control
                size="sm"
                type="text"
                name="studentSisters"
                  value={formData.studentSisters}
                onChange={handleInputChange}
                placeholder="Sisters"
              />
            </Form.Group>
          </Col>
        </Row>
        <Col>
          <Form.Group controlId="">
            <Form.Label>Identity Mark</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="studentIdentityMark"
                value={formData.studentIdentityMark}
              onChange={handleInputChange}
              placeholder="Identity Mark"
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="">
            <Form.Label>Religion</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="studentReligion"
                value={formData.studentReligion}
              onChange={handleInputChange}
              placeholder="Identity Mark"
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="">
            <Form.Label>Any Disability</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="studentDisability"
                value={formData.studentDisability}
              onChange={handleInputChange}
              placeholder="Identity Mark"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row style={{ padding: 2 }}>
        <Col xs={2}>
          <Form.Group controlId="">
            <Form.Label>Mother Language</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="studentMotherLanguage"
                value={formData.studentMotherLanguage}
              onChange={handleInputChange}
              placeholder="Language"
            />
          </Form.Group>
        </Col>
        <Col xs={2}>
          <Form.Group controlId="">
            <Form.Label>Child Monthly Expense</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="studentMonthlyExpense"
                value={formData.studentMonthlyExpense}
              onChange={handleInputChange}
              placeholder="Monthly Exps"
            />
          </Form.Group>
        </Col>
        <Col xs={3}>
          <Form.Group controlId="">
            <Form.Label>Future Career Goals</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="studentGoal"
                value={formData.studentGoal}
              onChange={handleInputChange}
              placeholder="Future Goals"
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="">
            <Form.Label>Anyone Taking Care of the Child Before?</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="studentHaveCareTaker"
                value={formData.studentHaveCareTaker}
              onChange={handleInputChange}
              placeholder="Person Name"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row style={{ padding: 2 }}>
        <Col>
          <Form.Group controlId="">
            <Form.Label>Present Address</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="studentPresentAddress"
                value={formData.studentPresentAddress}
              onChange={handleInputChange}
              placeholder="Present Address"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row style={{ padding: 2 }}>
        <Col>
          <Form.Group controlId="">
            <Form.Label>Permanent Address</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="studentPermanentAddress"
                value={formData.studentPermanentAddress}
              onChange={handleInputChange}
              placeholder="Permanent Address"
            />
          </Form.Group>
        </Col>
      </Row>
    </Row>
  );
}
