import React, { useState } from "react";
import { Form, Row, Col, Table, Button } from "react-bootstrap";

export default function Documents({formData, updateFormData}) {
  const handleInputChange = (event) => {
    const { name, files } = event.target;

    console.log(name,files);
    const file = files[0];
    updateFormData({ ...formData, [name]: file });
    console.log(formData)
  };

  return (
    <Row style={{ backgroundColor: "white", padding: 10 }}>
      <Row
        style={{
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
          borderBottom: "1px solid lightgrey",
          padding: 5,
          marginBottom: 10,
        }}
      >
        <Col className="d-flex" style={{ justifyContent: "center" }}>
          <h5>Documents</h5>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>
              <b>1. </b>Application
            </Form.Label>
            <Form.Control type="file" />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>
              <b>2. </b>Child B-Form
            </Form.Label>
            <Form.Control type="file"
                          name="studentBform"
                          onChange={handleInputChange}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>
              <b>3. </b>Parents / Guardian Affidavit
            </Form.Label>
            <Form.Control type="file"

            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row>
            <Form.Label>
              <b>4. </b>Parents / Guardian CNIC (Front & Back)
            </Form.Label>
          </Row>
          <Row>
            <Col>
              <Form.Control type="file"
                            name="studentFatherCNICFront"
                            onChange={handleInputChange}
              />
            </Col>
            <Col>
              <Form.Control type="file"
                            name="studentFatherCNICBack"
                            onChange={handleInputChange}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>
              <b>5. </b>Child Medical Certificate
            </Form.Label>
            <Form.Control type="file"
                          name="studentHealthScreenReport"
                          onChange={handleInputChange}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>
              <b>6. </b>Parents Medical Certificate
            </Form.Label>
            <Form.Control type="file" />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>
              <b>7. </b>School / Mudrasa Leaving Certificate
            </Form.Label>
            <Form.Control type="file" />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>
              <b>8. </b>Death Certificate of Deceased Parents
            </Form.Label>
            <Form.Control type="file"
                          name="studentFatherDeathCertificate"
                          onChange={handleInputChange}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>
              <b>9. </b>Income Certificate of Family / Legal Guardian
            </Form.Label>
            <Form.Control type="file" />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>
              <b>10. </b>Child Photograph
            </Form.Label>
            <Form.Control type="file"
                          name="studentImage"
                          onChange={handleInputChange}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>
              <b>11. </b>Electricity Bill of Current Residence
            </Form.Label>
            <Form.Control type="file" />
          </Form.Group>
        </Col>
      </Row>
    </Row>
  );
}
