import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";

export default function BankInfo({formData, updateFormData}) {
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        updateFormData({ ...formData, [name]: value });
    };
  return (
    <Row style={{ backgroundColor: "white", padding: 10 }}>
      <Row
        style={{
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
          borderBottom: "1px solid lightgrey",
          padding: 5,
          marginBottom: 10,
        }}
      >
        <h5>Mother/Guardian Bank Information</h5>
      </Row>
      <Row style={{ padding: 2 }}>
        <Col xs={8}>
          <Form.Group controlId="">
            <Form.Label>Bank Name</Form.Label>
            <Form.Control
              type="text"
              name="guardianAccountName"
                value={formData.guardianAccountName}
              onChange={handleInputChange}
              placeholder="Bank Name"
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="">
            <Form.Label>Branch Code</Form.Label>
            <Form.Control
              type="text"
              name="guardianBranchCode"
                value={formData.guardianBranchCode}
              onChange={handleInputChange}
              placeholder="Branch Code"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row style={{ padding: 2 }}>
        <Col>
          <Form.Group controlId="">
            <Form.Label>Branch Address</Form.Label>
            <Form.Control
              type="text"
              name="guardianBranchAddress"
                value={formData.guardianBranchAddress}
              onChange={handleInputChange}
              placeholder="Branch Address"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row style={{ padding: 2 }}>
        <Col>
          <Form.Group controlId="">
            <Form.Label>Account Title</Form.Label>
            <Form.Control
              type="text"
              name="guardianAccountTitle"
                value={formData.guardianAccountTitle}
              onChange={handleInputChange}
              placeholder="Account Title"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row style={{ padding: 2 }}>
        <Col>
          <Form.Group controlId="">
            <Form.Label>Account No.</Form.Label>
            <Form.Control
              type="text"
              name="guardianAccountNo"
                value={formData.guardianAccountNo}
              onChange={handleInputChange}
              placeholder="Account No."
            />
          </Form.Group>
        </Col>
      </Row>
    </Row>
  );
}
