import React, { useEffect, useState } from "react";
import axios from 'axios'
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Image,
  Alert,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import * as Images from "../assets/images/index";
import { useNavigate } from "react-router-dom";


function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
  const history = useNavigate();
    const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("user-info")) {
        navigate("/dashboard");
    }
  }, []);

  async function login(e) {
    e.preventDefault();
      let formData = new FormData();
      formData.append("email", email);
      formData.append("password", password);

      axios.post('https://lihsorphanages.com/lihs-app/public/index.php/api/userLogin', formData)
          .then(response => {
              if (response.data.status === 200) {
                  const userInfo = response.data;
                  localStorage.setItem("user-info", JSON.stringify(userInfo));
                  navigate("/dashboard");
              } else {
                  const errorMessage = response.data.message;
                  setErrorMessage(errorMessage);
              }

          })
          .catch(e => {
            console.log(e)
          });
  }

  return (
    <Container
      className="vh-100"
      style={{ backgroundColor: "#f3f3f3" }}
      fluid={true}
    >
      <Row style={{ paddingTop: 65, justifyContent: "center" }}>
        <Col
          xs={8}
          style={{
            backgroundColor: "#fbfbfb",
            height: "100%",
            borderRadius: 20,
            border: "1px solid",
            borderColor: "#e5e5e5",
            shadowColor: "#000",
            shadowOffset: {
              width: 0,
              height: 2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 3.84,
            elevation: 5,
          }}
        >
          <Row>
            <Col style={{ padding: 5 }}>
              <Row style={{ margin: 10 }}>
                <Col xs={3}>
                  <Image src={Images.logo} fluid />
                </Col>
              </Row>
              <Row style={{ margin: 10 }}>
                <Col>
                  <h3>Sign In</h3>
                </Col>
              </Row>
              <Row style={{ margin: 10 }}>
                <Col>
                  <h6>Enter your Email & Password</h6>
                </Col>
              </Row>
              <form onSubmit={login}>
                <Row style={{ margin: 10, marginTop: 15 }}>
                  <Col>
                    <Form.Group>
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Email"
                        name="email"
                        autoComplete="off"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row style={{ margin: 10 }}>
                  <Col>
                    <Form.Group>
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Password"
                        name="password"
                        autoComplete="off"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                    {errorMessage && <p className="text-danger">{errorMessage}</p>}
                </Row>
                  <Row style={{ margin: 10 }}>
                  <Col>
                    <Form.Check
                      style={{ fontSize: 14 }}
                      type="checkbox"
                      label="Remember me"
                    />
                  </Col>
                  <Col style={{ textAlign: "right", fontSize: 14 }}>
                    <a style={{ textDecoration: "none" }} href="/dashboard">
                      Forgot Password?
                    </a>
                  </Col>
                </Row>
                <Row style={{ margin: 30, marginBottom: 10 }}>
                  <Col style={{ textAlign: "center" }}>
                    <Button type="submit">
                      Sign In
                    </Button>
                  </Col>
                </Row>
              </form>
              <Row style={{ margin: 30, marginBottom: 10 }}>
                <Col
                  style={{ textAlign: "center", fontSize: 12, color: "grey" }}
                >
                  Join our Social Media
                </Col>
              </Row>
              <Row
                style={{
                  margin: 0,
                  marginBottom: 30,
                  justifyContent: "center",
                }}
              >
                <Col
                  xs={2}
                  style={{
                    width: 60,
                    textAlign: "Right",
                    fontSize: 12,
                    color: "grey",
                  }}
                >
                  <Image src={Images.fb} fluid />
                </Col>
                <Col
                  xs={2}
                  style={{
                    width: 60,
                    textAlign: "Right",
                    fontSize: 12,
                    color: "grey",
                  }}
                >
                  <Image src={Images.insta} fluid />
                </Col>
                <Col
                  xs={2}
                  style={{
                    width: 60,
                    textAlign: "Right",
                    fontSize: 12,
                    color: "grey",
                  }}
                >
                  <Image src={Images.twitter} fluid />
                </Col>
              </Row>
            </Col>
            <Col
              md={6}
              style={{
                backgroundImage: `url(${Images.lihs})`,
                borderTopRightRadius: 20,
                borderBottomRightRadius: 20,
              }}
            ></Col>
          </Row>
        </Col>
        {/* <Col xs={2}></Col> */}
      </Row>
    </Container>
  );
}

export default SignIn;
