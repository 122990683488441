import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  ProgressBar,
  Row,
  Col,
  Container,
} from "react-bootstrap";
import BasicInformation from "./Form/BasicInformation";
import ChildInfo from "./Form/ChildInfo";
import SchoolInfo from "./Form/SchoolInfo";
import FatherInfo from "./Form/FatherInfo";
import MotherInfo from "./Form/MotherInfo";
import GuardianInfo from "./Form/GuardianInfo";
import BankInfo from "./Form/BankInfo";
import FamilyInfo from "./Form/FamilyInfo";
import VisitorsInfo from "./Form/VisitorsInfo";
import Documents from "./Form/Documents";
import axios from "axios";

const MultiStepForm = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({});
  const [registerationForm, setRegistrationForm] = useState(false);
  const [student_id, setStudentId] = useState('');
  const [campusId, setCampusId] = useState('');
  const [segment, setSegment] = useState('');
  const [sendRequest , setsendRequest] = useState(false)

  useEffect(() => {
    setSegmentValue();
  }, [step]); // This will trigger the effect whenever `step` changes

  const handleNext = async () => {
    if (sendRequest){
      return
    }
    if (step === 1) {
      await registerStudent();
    } else {
      if (segment == 'studentFamilyMember') {
        setStep(step + 1);
      } else {
        await saveInfo();
      }
    }
  };

  const setSegmentValue = () => {
    let segmentValue = '';
    switch (step) {
      case 2:
        segmentValue = 'studentMoreInfo';
        break;
      case 3:
        segmentValue = 'schoolInfo';
        break;
      case 4:
        segmentValue = 'fatherInfo';
        break;
      case 5:
        segmentValue = 'motherInfo';
        break;
      case 6:
        segmentValue = 'guardianInfo';
        break;
      case 7:
        segmentValue = 'guardianAccountInfo';
        break;
      case 8:
        segmentValue = 'studentFamilyMember';
        break;
      case 9:
        segmentValue = 'studentFamilyMember';
        break;
      case 10:
        segmentValue = 'studentYearlyImage';
        break;
      default:
        break;
    }
    setSegment(segmentValue);
  };

  const saveInfo = () => {
    let user_info = JSON.parse(localStorage.getItem('user-info'));

    if (user_info) {
      const config = {
        headers: {
          'Authorization': `Bearer ${user_info.token}`,
          'Content-Type': 'multipart/form-data'
        }
      };

      const formDataWithToken = {
        ...formData,
        segment: segment,
        studentId: student_id,
        campusId: campusId,
        api_token: user_info.token
      };
      setsendRequest(true)
      axios.post('https://lihsorphanages.com/lihs-app/public/index.php/api/editStudentInfo', formDataWithToken, config)
          .then(response => {
            setsendRequest(false)
            if (response.data.status === 200) {
              setStep(step + 1);
              if (step === 10) {
                setStep(1);
                setFormData({});
                setSegment('');
                setCampusId('');
                setStudentId('');
              }

            } else {
              alert(response.data.message);
            }
          })
          .catch(e => {
            setsendRequest(false)
            console.log(e);
          });
    }
  };

  const registerStudent = () => {
    let user_info = JSON.parse(localStorage.getItem('user-info'));
    if (user_info) {
      const config = {
        headers: {
          'Authorization': `Bearer ${user_info.token}`,
          'Content-Type': 'multipart/form-data'
        }
      };
      const formDataWithToken = {
        ...formData,
        api_token: user_info.token
      };
      setsendRequest(true)
      axios.post('https://lihsorphanages.com/lihs-app/public/index.php/api/studentRegisteration', formDataWithToken, config)
          .then(response => {
            setsendRequest(false)
            if (response.data.status === 200) {
              setStudentId(response.data.data.id);
              setCampusId(response.data.data.campusId);
              setFormData({});
              setStep(step + 1);
            } else {
              alert(response.data.message);
            }
          })
          .catch(e => {
            setsendRequest(false)
            console.log(e);
          });
    }
  };

  const handlePrevious = () => {
    if (step === 2) {
      return;
    } else {
      setStep(step - 1);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (sendRequest){
      return
    }
    saveInfo();
  };

  const updateFormData = (data) => {
    setFormData({ ...formData, ...data });
  };

  return (
      <Form onSubmit={handleSubmit}>
        <Row style={{ backgroundColor: "white", padding: 10, borderRadius: 10 }}>
          <Container>
            <ProgressBar now={(step / 10) * 100} />
            {step === 1 && <BasicInformation formData={formData} updateFormData={updateFormData} />}
            {step === 2 && <ChildInfo formData={formData} updateFormData={updateFormData} />}
            {step === 3 && <SchoolInfo formData={formData} updateFormData={updateFormData} />}
            {step === 4 && <FatherInfo formData={formData} updateFormData={updateFormData} />}
            {step === 5 && <MotherInfo formData={formData} updateFormData={updateFormData} />}
            {step === 6 && <GuardianInfo formData={formData} updateFormData={updateFormData} />}
            {step === 7 && <BankInfo formData={formData} updateFormData={updateFormData} />}
            {step === 8 && <FamilyInfo formData={formData} updateFormData={updateFormData} />}
            {step === 9 && <VisitorsInfo formData={formData} updateFormData={updateFormData} />}
            {step === 10 && <Documents formData={formData} updateFormData={updateFormData} />}
            <div className="d-flex justify-content-around">
              {step > 1 && (
                  <Button variant="secondary" onClick={handlePrevious}>
                    Previous
                  </Button>
              )}
              {step < 10 ? (
                  <Button variant="primary" onClick={handleNext}>
                    Next
                  </Button>
              ) : (
                  <Button variant="primary" type="submit">
                    Submit
                  </Button>
              )}
            </div>
          </Container>
        </Row>
      </Form>
  );
};

export default MultiStepForm;
