import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";

export default function BasicInformation({formData, updateFormData}) {
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    updateFormData({ ...formData, [name]: value });
  };

  return (
    <Row style={{ backgroundColor: "white", padding: 10 }}>
      <Row
        style={{
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
          borderBottom: "1px solid lightgrey",
          padding: 5,
          marginBottom: 10,
        }}
      >
        <h5>Basic Information</h5>
      </Row>
      <Row style={{padding:2}}>
        <Col>
          <Form.Group controlId="">
            <Form.Label>Form No.</Form.Label>
            <Form.Control
              type="text"
              name="studentFormNo"
            //   value={formData.email}
              onChange={handleInputChange}
              placeholder="Form No."
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="">
            <Form.Label>Registeration No.</Form.Label>
            <Form.Control
              type="text"
              name="studentRegistrationNo"
            //   value={formData.email}
              onChange={handleInputChange}
              placeholder="Registeration No."
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="">
            <Form.Label>ERP ID</Form.Label>
            <Form.Control
              type="text"
              name="erpId"
            //   value={formData.email}
              onChange={handleInputChange}
              placeholder="ERP ID"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row  style={{padding:2}}>
        <Col>
          <Form.Group controlId="">
            <Form.Label>Campus Name</Form.Label>
            <Form.Select aria-label="Default select example" onChange={(event) => handleInputChange({ target: { name: "campusId", value: event.target.value } })}>
              <option>Select Campus</option>
              <option value="1">MCH LHR</option>
              <option value="2">Kashana LHR</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="">
            <Form.Label>Region</Form.Label>
            <Form.Select aria-label="Default select example" onChange={(event) => handleInputChange({ target: { name: "studentRegion", value: event.target.value } })}>
              <option>Select Region</option>
              <option value="Punjab">Punjab</option>
              <option value="Other">Other</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="">
            <Form.Label>Date of Admission</Form.Label>
            <Form.Control
              type="date"
              name="studentAdmissionDate"
            //   value={formData.email}
              onChange={handleInputChange}
              placeholder="Date of Admission"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row  style={{padding:2}}>
        <Col>
          <Form.Group controlId="">
            <Form.Label>District</Form.Label>
            <Form.Control
              type="text"
              name="studentDistrict"
            //   value={formData.email}
              onChange={handleInputChange}
              placeholder="District"
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="">
            <Form.Label>City</Form.Label>
            <Form.Control
              type="text"
              name="studentCity"
            //   value={formData.email}
              onChange={handleInputChange}
              placeholder="City"
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="">
            <Form.Label>Category of Child</Form.Label>
            {/*{["checkbox"].map((type) => (*/}
            {/*  <div key={`inline-${type}`} className="mb-3">*/}
            {/*    <Form.Check*/}
            {/*      inline*/}
            {/*      label="Orphan"*/}
            {/*      name="group1"*/}
            {/*      type={type}*/}
            {/*      id={`inline-${type}-1`}*/}
            {/*    />*/}
            {/*    <Form.Check*/}
            {/*      inline*/}
            {/*      label="Poor"*/}
            {/*      name="group1"*/}
            {/*      type={type}*/}
            {/*      id={`inline-${type}-2`}*/}
            {/*    />*/}
            {/*    <Form.Check*/}
            {/*      inline*/}
            {/*      label="Other"*/}
            {/*      type={type}*/}
            {/*      id={`inline-${type}-3`}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*))}*/}
            {[
              { label: "Orphan", value: "0" },
              { label: "Poor", value: "1" },
              { label: "Other", value: "2" }
            ].map((checkbox, index) => (
                <div key={index} className="mb-3">
                  <Form.Check
                      inline
                      label={checkbox.label}
                      name="studentIsChildOrphan"
                      type="checkbox"
                      value={checkbox.value}
                      onChange={handleInputChange}
                      id={`inline-checkbox-${index}`}
                  />
                </div>
            ))}
          </Form.Group>
        </Col>
      </Row>
    </Row>
  );
}
