import React from "react";
import { Container, Row, Col, Form, Button, Table } from "react-bootstrap";
import Navbar from "../Components/Navbar";
import { Bell, Pencil, PersonCircle } from "react-bootstrap-icons";
import * as Images from "../assets/images/index";
import PieChart from "../Components/PieChart";
import AttendanceChart from "../Components/AttendanceChart";

function StudentProfile() {
  return (
    <Container style={{ backgroundColor: "#f3f3f3" }} fluid>
      <Row>
        <Navbar />
        <Col style={{ padding: 20, paddingLeft: 30, paddingRight: 30 }}>
          <Row>
            <Col>
              <Row>
                <Col>
                  <p
                    style={{
                      marginBottom: -4,
                      fontSize: 14,
                      fontWeight: "400",
                    }}
                  >
                    Dashboard / Students / Profile
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p style={{ fontSize: 24, fontWeight: "600" }}>
                    Student Profile
                  </p>
                </Col>
              </Row>
            </Col>
            <Col xs={6}>
              <Row className="justify-content-end">
                <Col
                  xs={6}
                  style={{
                    backgroundColor: "white",
                    borderRadius: 100,
                    border: "1px solid",
                    borderColor: "#e5e5e5",
                  }}
                >
                  <Row style={{ paddingTop: 5, paddingBottom: 5 }}>
                    <Col xs={6}>
                      <Form.Group className="w-100">
                        <Form.Control
                          style={{
                            borderRadius: 100,
                            backgroundColor: "#f3f3f3",
                          }}
                          placeholder="search"
                        />
                      </Form.Group>
                    </Col>
                    <Col
                      xs={2}
                      className="d-flex align-items-center justify-content-center"
                    >
                      <Bell />
                    </Col>
                    <Col
                      xs={2}
                      className="d-flex align-items-center justify-content-center"
                    >
                      <Bell />
                    </Col>
                    <Col
                      xs={2}
                      className="d-flex align-items-center justify-content-center"
                    >
                      <PersonCircle size="25px" />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col
              style={{
                marginRight: 10,
              }}
              xs={9}
            >
              <Row
                style={{
                  backgroundColor: "white",
                  borderRadius: 10,
                  padding: 10,
                  marginBottom: 10,
                }}
              >
                <Col
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderBottom: "1px solid lightgrey",
                  }}
                  className="d-flex"
                >
                  <h5>Academic Record</h5>
                  <Pencil />
                </Col>
                <Table hover>
                  <thead>
                    <tr>
                      <th style={{ fontSize: 14 }}>Class</th>
                      <th style={{ fontSize: 14 }} colSpan={2}>
                        School
                      </th>
                      <th style={{ fontSize: 14 }}>Year</th>
                      <th style={{ fontSize: 14 }} colSpan={2}>
                        Percentage
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ fontSize: 14 }}>8th</td>
                      <td style={{ fontSize: 14 }} colSpan={2}>
                        The Educators
                      </td>
                      <td style={{ fontSize: 14 }}>2020</td>
                      <td style={{ fontSize: 14 }} colSpan={2}>
                        81%
                      </td>
                    </tr>
                    <tr>
                      <td style={{ fontSize: 14 }}>7th</td>
                      <td style={{ fontSize: 14 }} colSpan={2}>
                        Govt. Higher Secondary School
                      </td>
                      <td style={{ fontSize: 14 }}>2019</td>
                      <td style={{ fontSize: 14 }} colSpan={2}>
                        58%
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Row>
              {/* <Row>
                <Col
                  style={{
                    backgroundColor: "white",
                    borderRadius: 10,
                    marginRight: 5,
                    padding: 10,
                    marginBottom: 10,
                  }}
                >
                  <Col
                    as={Col}
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderBottom: "1px solid lightgrey",
                      paddingRight: 10,
                      paddingLeft: 10,
                    }}
                    className="d-flex"
                  >
                    <h5>Progress Report</h5>
                    <Pencil />
                  </Col>
                  <PieChart />
                </Col>
                <Col
                  style={{
                    backgroundColor: "white",
                    borderRadius: 10,
                    marginRight: 5,
                    padding: 10,
                    marginBottom: 10,
                  }}
                >
                  <Col
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderBottom: "1px solid lightgrey",
                      paddingRight: 10,
                      paddingLeft: 10,
                    }}
                    className="d-flex"
                  >
                    <h5> Report</h5>
                    <Pencil />
                  </Col>
                  <PieChart />
                </Col>
                
              </Row> */}
              <Row>
                <Col
                  style={{
                    backgroundColor: "white",
                    borderRadius: 10,
                    marginRight: 5,
                    padding: 10,
                  }}
                >
                  <Col
                    as={Col}
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderBottom: "1px solid lightgrey",
                      paddingRight: 10,
                      paddingLeft: 10,
                    }}
                    className="d-flex"
                  >
                    <h5>Attendance</h5>
                    <Pencil />
                  </Col>
                  <AttendanceChart />
                </Col>
              </Row>
            </Col>
            <Col
              style={{
                padding: 20,
                backgroundColor: "white",
                borderRadius: 10,
                justifyContent: "center",
              }}
            >
              <Row xs={2} style={{ justifyContent: "center" }}>
                <img src={Images.logo} alt="Child Image" fluid />
              </Row>
              <Row
                style={{
                  textAlign: "center",
                  marginBottom: 2,
                  borderBottom: "1px solid lightgrey",
                }}
              >
                <h4 style={{ fontWeight: "bold" }}>John Doe</h4>
              </Row>
              <Row style={{ marginTop: 7 }}>
                <p
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: 16,
                    marginBottom: 2,
                  }}
                >
                  Personal Information
                </p>
              </Row>
              <Row
                style={{
                  padding: 0,
                  margin: 0,
                  borderBottom: "1px solid lightgrey",
                  paddingBottom: 10,
                }}
              >
                <p style={{ padding: 0, margin: 0, fontSize: 14 }}>
                  <b>Name: </b>John Doe
                </p>
                <p style={{ padding: 0, margin: 0, fontSize: 14 }}>
                  <b>Father Name: </b>John Doe
                </p>
                <p style={{ padding: 0, margin: 0, fontSize: 14 }}>
                  <b>Class: </b>7th
                </p>
                <p style={{ padding: 0, margin: 0, fontSize: 14 }}>
                  <b>CNIC: </b>XXXXX-XXXXXXX-X
                </p>
                <p style={{ padding: 0, margin: 0, fontSize: 14 }}>
                  <b>Address: </b>123-C Street Y ABC Colony City, Province,
                  Pakistan
                </p>
              </Row>
              <Row style={{ marginTop: 7 }}>
                <p
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: 16,
                    marginBottom: 2,
                  }}
                >
                  Parents/Guardian Information
                </p>
              </Row>
              <Row
                style={{
                  padding: 0,
                  margin: 0,
                  borderBottom: "1px solid lightgrey",
                  paddingBottom: 10,
                }}
              >
                <p style={{ padding: 0, margin: 0, fontSize: 14 }}>
                  <b>Name: </b>John Doe
                </p>
                <p style={{ padding: 0, margin: 0, fontSize: 14 }}>
                  <b>Contact: </b>XXXX-XXXXXXX
                </p>
                <p style={{ padding: 0, margin: 0, fontSize: 14 }}>
                  <b>CNIC: </b>XXXXX-XXXXXXX-X
                </p>
                <p style={{ padding: 0, margin: 0, fontSize: 14 }}>
                  <b>Address: </b>123-C Street Y ABC Colony City, Province,
                  Pakistan
                </p>
              </Row>
              <Row style={{ marginTop: 7 }}>
                <p
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: 16,
                    marginBottom: 2,
                  }}
                >
                  Boarding Information
                </p>
              </Row>
              <Row
                style={{
                  padding: 0,
                  margin: 0,
                }}
              >
                <p style={{ padding: 0, margin: 0, fontSize: 14 }}>
                  <b>Building: </b> ABC
                </p>
                <p style={{ padding: 0, margin: 0, fontSize: 14 }}>
                  <b>Room: </b>01
                </p>
                <p style={{ padding: 0, margin: 0, fontSize: 14 }}>
                  <b>Bed: </b>54
                </p>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default StudentProfile;
