import React from "react";
import {
  Container,
  Row,
  Col,
  Form,
  ButtonGroup,
  Table,
  Button,
} from "react-bootstrap";
import Navbar from "../Components/Navbar";
import { Bell, PersonCircle, BarChartFill } from "react-bootstrap-icons";

function Education() {
  return (
    <Container style={{ backgroundColor: "#f3f3f3" }} fluid>
      <Row>
        <Navbar />
        <Col style={{ padding: 20, paddingLeft: 30, paddingRight: 30 }}>
          <Row>
            <Col>
              <Row>
                <Col>
                  <p
                    style={{
                      marginBottom: -4,
                      fontSize: 14,
                      fontWeight: "400",
                    }}
                  >
                    Dashboard / Education
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p style={{ fontSize: 24, fontWeight: "600" }}>Education</p>
                </Col>
              </Row>
            </Col>
            <Col xs={6}>
              <Row className="justify-content-end">
                <Col
                  xs={6}
                  style={{
                    backgroundColor: "white",
                    borderRadius: 100,
                    border: "1px solid",
                    borderColor: "#e5e5e5",
                  }}
                >
                  <Row style={{ paddingTop: 5, paddingBottom: 5 }}>
                    <Col xs={6}>
                      <Form.Group className="w-100">
                        <Form.Control
                          style={{
                            borderRadius: 100,
                            backgroundColor: "#f3f3f3",
                          }}
                          placeholder="search"
                        />
                      </Form.Group>
                    </Col>
                    <Col
                      xs={2}
                      className="d-flex align-items-center justify-content-center"
                    >
                      <Bell />
                    </Col>
                    <Col
                      xs={2}
                      className="d-flex align-items-center justify-content-center"
                    >
                      <Bell />
                    </Col>
                    <Col
                      xs={2}
                      className="d-flex align-items-center justify-content-center"
                    >
                      <PersonCircle size="25px" />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col
              className="d-flex align-items-center justify-content-center"
              style={{
                backgroundColor: "white",
                margin: 3,
                marginBottom: 0,
                marginRight: 5,
                borderRadius: 10,
                padding: 5,
                border: "1px solid #e5e5e5",
              }}
            >
              <Row>
                <h6>Campus Results</h6>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default Education;
