import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";

export default function FatherInfo({formData, updateFormData}) {
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    updateFormData({ ...formData, [name]: value });
  };
  return (
    <Row style={{ backgroundColor: "white", padding: 10 }}>
      <Row
        style={{
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
          borderBottom: "1px solid lightgrey",
          padding: 5,
          marginBottom: 10,
        }}
      >
        <h5>Father Information</h5>
      </Row>
      <Row style={{ padding: 2 }}>
        <Col xs={6}>
          <Form.Group controlId="">
            <Form.Label>Father Name</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="fatherName"
                value={formData.fatherName}
              onChange={handleInputChange}
              placeholder="Father Name"
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="">
            <Form.Label>CNIC No.</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="fatherCnicNo"
                value={formData.fatherCnicNo}
              onChange={handleInputChange}
              placeholder="Father CNIC No"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row style={{ padding: 2 }}>
        <Col>
          <Form.Group controlId="">
            <Form.Label>Is he Alive?</Form.Label>
            <Form.Select
                aria-label="Default select example"
                size="sm"
                name="fatherIsAlive"
                value={formData.fatherIsAlive}
                onChange={handleInputChange}
            >
              <option>Select Option</option>
              <option value="1">Yes</option>
              <option value="2">No</option>
              <option value="3">Missing</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="">
            <Form.Label>Date of Death / Missing</Form.Label>
            <Form.Control
              size="sm"
              type="date"
              name="fatherDateOfDeath"
                value={formData.fatherDateOfDeath}
              onChange={handleInputChange}
              placeholder="Date of Death / Missing"
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="">
            <Form.Label>Reason of Death / Missing</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="fatherReasonOfDeath"
                value={formData.fatherReasonOfDeath}
              onChange={handleInputChange}
              placeholder="Reason of Death / Missing"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row style={{ padding: 2 }}>
        <Col>
          <Form.Group controlId="">
            <Form.Label>Occupation</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="fatherOccupation"
                value={formData.fatherOccupation}
              onChange={handleInputChange}
              placeholder="Occupation"
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="">
            <Form.Label>Position</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="fatherDesignation"
                value={formData.fatherDesignation}
              onChange={handleInputChange}
              placeholder="Position"
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="">
            <Form.Label>Mobile No.</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="fatherMobileNo"
                value={formData.fatherMobileNo}
              onChange={handleInputChange}
              placeholder="Mobile No."
            />
          </Form.Group>
        </Col>
      </Row>
      <Row style={{ padding: 2 }}>
        <Col>
          <Form.Group controlId="">
            <Form.Label>Reason of Admission</Form.Label>
            <Form.Select aria-label="Default select example" size="sm"
                 name="reasonOfAdmission"
                 value={formData.reasonOfAdmission}
                 onChange={handleInputChange}
            >
              <option>Select Option</option>
              <option value="1">Disabled</option>
              <option value="2">Addict</option>
              <option value="3">Other</option>
            </Form.Select>
          </Form.Group>
        </Col>

        {/*<Col>*/}
        {/*  <Form.Group controlId="">*/}
        {/*    <Form.Label>Mobile No.</Form.Label>*/}
        {/*    <Form.Control*/}
        {/*      size="sm"*/}
        {/*      type="text"*/}
        {/*      name="fatherMobileNo"*/}
        {/*        value={formData.fatherMobileNo}*/}
        {/*      onChange={handleInputChange}*/}
        {/*      placeholder="Mobile No."*/}
        {/*    />*/}
        {/*  </Form.Group>*/}
        {/*</Col>*/}
      </Row>
      {/* <Row style={{ padding: 10 }}>
        <Col></Col>
        <Col style={{ border: "1px solid lightgrey", margin: 10 }}>
          <img alt="Father Fingerprint" />
        </Col>
        <Col></Col>
      </Row> */}
    </Row>
  );
}
