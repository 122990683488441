import React, { useState } from "react";
import { Form, Row, Col, Table, Button } from "react-bootstrap";

export default function VisitorsInfo() {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({});

  const handleNext = () => {
    setStep(step + 1);
  };

  const handlePrevious = () => {
    setStep(step - 1);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // handle form submission
  };
  return (
    <Row style={{ backgroundColor: "white", padding: 10 }}>
      <Row
        style={{
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
          borderBottom: "1px solid lightgrey",
          padding: 5,
          marginBottom: 10,
        }}
      >
        <Col xs={7} className="d-flex" style={{ justifyContent: "end" }}>
          <h5>Authorized Family Visitors Details</h5>
        </Col>
        <Col className="d-flex" style={{ justifyContent: "end" }}>
          <Button>+ Add New Member</Button>
        </Col>
      </Row>
      <Row>
        <Table striped>
          <thead>
            <tr>
              <th>Sr. #</th>
              <th>Name</th>
              <th>Gender</th>
              <th>Age</th>
              <th>Education</th>
              <th>Occupation</th>
              <th>Marital Status</th>
              <th>Contact #</th>
              <th>Address</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Ali</td>
              <td>Male</td>
              <td>18</td>
              <td>FSC</td>
              <td>Cook</td>
              <td>Single</td>
              <td>0300-1234567</td>
              <td>MCH Samanabad LHR</td>
            </tr>
            <tr>
              <td>2</td>
              <td>Zain</td>
              <td>Male</td>
              <td>24</td>
              <td>BS hons.</td>
              <td>Accountant</td>
              <td>Married</td>
              <td>0300-1234567</td>
              <td>Ichra LHR</td>
            </tr>
            <tr>
              <td>3</td>
              <td>Ayesha</td>
              <td>Female</td>
              <td>10</td>
              <td>Primary</td>
              <td>Student</td>
              <td>Single</td>
              <td>0300-1234567</td>
              <td>Shadman LHR</td>
            </tr>
          </tbody>
        </Table>
      </Row>
    </Row>
  );
}
