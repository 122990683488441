import React from "react";
import axios from 'axios'

import { Row, Col, Image,Button } from "react-bootstrap";
import * as Images from "../assets/images/index";
// import "./Navbar.css";
import {
  BookHalf,
  DoorOpenFill,
  FileEarmarkBarGraphFill,
  FileEarmarkPersonFill,
  GridFill,
  PersonBoundingBox,
  PersonCircle,
} from "react-bootstrap-icons";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";


function Navbar() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const navigate = useNavigate();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };
    const handleLogout = () => {
        // console.log(window.localStorage.getItem("user-info"));

        let user_info = JSON.parse(localStorage.getItem('user-info'))
        if (user_info){
            let formData = new FormData();
            formData.append("api_token", user_info.token);
            axios.post('https://lihsorphanages.com/lihs-app/public/index.php/api/userLogout', formData)
                .then(response => {
                    if (response.data.status === 200) {
                        localStorage.removeItem("user-info");
                        navigate("/");
                    } else {
                        console.log(response.data.message)
                    }

                })
                .catch(e => {
                    console.log(e)
                });
        }

    };
  return (
    <>
      <Col
        className={`app ${
          isSidebarOpen ? "sidebar-open" : "sidebar-closed"
        } vh-100`}
        style={{
          backgroundColor: "white",
          borderTopLeftRadius: 20,
          borderBottomLeftRadius: 20,
          padding: 10,
          paddingLeft: 30,
          paddingRight: 30,
        }}
        xs={2}
      >
        <nav>
          <Row style={{}}>
            <Col>
              <Row onClick={toggleSidebar}>
                <Col xs={6}>
                  <Image src={Images.logo} fluid />
                </Col>
              </Row>
              <Row
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                  borderBottom: "1px solid",
                  borderColor: "lightgrey",
                }}
              >
                <Col>
                  <p
                    style={{
                      fontSize: 14,
                      fontWeight: "bold",
                      marginBottom: -6,
                      color: "#1b1b1b",
                    }}
                  >
                    LAHORE INSTITUTE
                  </p>
                  <p style={{ fontSize: 14, margin: 0, marginBottom: 10 }}>
                    OF HEALTH SCIENCES
                  </p>
                </Col>
              </Row>
              <Link
                to={"/dashboard"}
                style={{
                  textDecoration: "none",
                  color: "black",
                }}
              >
                <Row
                  style={{
                    marginTop: 20,
                    padding: 5,
                    paddingLeft: 0,
                    backgroundColor: isHovering ? "#f3f3f3" : "white",
                    // color: isHovering ? "" : "",
                  }}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <Col xs={3} className="text-start">
                    <GridFill />
                  </Col>
                  <Col
                    className="text-start align-items-center d-flex"
                    style={{ paddingLeft: 0 }}
                  >
                    <p
                      to={"/"}
                      style={{
                        fontSize: 18,
                        fontWeight: "400",
                        color: "#1b1b1b",
                        margin: 0,
                        textDecoration: "none",
                        color: "black",
                      }}
                    >
                      Dashboard
                    </p>
                  </Col>
                </Row>
              </Link>
              <Link
                to={"/student"}
                style={{ textDecoration: "none", color: "black" }}
              >
                <Row
                  style={{
                    marginTop: 10,
                  }}
                >
                  <Col xs={3} className="text-start">
                    <PersonBoundingBox />
                  </Col>
                  <Col
                    className="text-start align-items-center d-flex"
                    style={{ paddingLeft: 0 }}
                  >
                    <p
                      style={{
                        fontSize: 18,
                        fontWeight: "400",
                        color: "#1b1b1b",
                        margin: 0,
                      }}
                    >
                      Student
                    </p>
                  </Col>
                </Row>
              </Link>
              <Link
                style={{ textDecoration: "none", color: "black" }}
                to={"/newregisteration"}
              >
                <Row
                  style={{
                    marginTop: 10,
                  }}
                >
                  <Col xs={3} className="text-start">
                    <FileEarmarkPersonFill />
                  </Col>
                  <Col
                    className="text-start align-items-center d-flex"
                    style={{ paddingLeft: 0 }}
                  >
                    <p
                      style={{
                        fontSize: 18,
                        fontWeight: "400",
                        color: "#1b1b1b",
                        margin: 0,
                      }}
                    >
                      Admissions
                    </p>
                  </Col>
                </Row>
              </Link>
              <Link
                to={"/studentprofile"}
                style={{ textDecoration: "none", color: "black" }}
              >
                <Row style={{ marginTop: 10 }}>
                  <Col xs={3} className="text-start">
                    <PersonCircle />
                  </Col>
                  <Col
                    className="text-start align-items-center d-flex"
                    style={{ paddingLeft: 0 }}
                  >
                    <p
                      style={{
                        fontSize: 18,
                        fontWeight: "400",
                        color: "#1b1b1b",
                        margin: 0,
                      }}
                    >
                      Profile
                    </p>
                  </Col>
                </Row>
              </Link>
              <Link
                to={"/reports"}
                style={{ textDecoration: "none", color: "black" }}
              >
                <Row style={{ marginTop: 10 }}>
                  <Col xs={3} className="text-start">
                    <FileEarmarkBarGraphFill/>
                  </Col>
                  <Col
                    className="text-start align-items-center d-flex"
                    style={{ paddingLeft: 0 }}
                  >
                    <p
                      style={{
                        fontSize: 18,
                        fontWeight: "400",
                        color: "#1b1b1b",
                        margin: 0,
                      }}
                    >
                      Reports
                    </p>
                  </Col>
                </Row>
              </Link>
              <Link
                to={"/education"}
                style={{ textDecoration: "none", color: "black" }}
              >
                <Row style={{ marginTop: 10 }}>
                  <Col xs={3} className="text-start">
                    <BookHalf/>
                  </Col>
                  <Col
                    className="text-start align-items-center d-flex"
                    style={{ paddingLeft: 0 }}
                  >
                    <p
                      style={{
                        fontSize: 18,
                        fontWeight: "400",
                        color: "#1b1b1b",
                        margin: 0,
                      }}
                    >
                      Education
                    </p>
                  </Col>
                </Row>
              </Link>
              <Row style={{ marginTop: 10 }}>
                <Col xs={3} className="text-start">
                  <DoorOpenFill />
                </Col>
                <Col
                  className="text-start align-items-center d-flex"
                  style={{ paddingLeft: 0 }}
                >
                    <Button variant="link" onClick={handleLogout}>
                        <p
                            style={{
                                fontSize: 18,
                                fontWeight: "400",
                                color: "#1b1b1b",
                                margin: 0,
                                padding: 0,
                            }}
                        >
                            Sign Out
                        </p>
                    </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </nav>
      </Col>
    </>
  );
}
export default Navbar;
