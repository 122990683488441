import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  ButtonGroup,
  Table,
  Button,
} from "react-bootstrap";
import Navbar from "../Components/Navbar";
import { Bell, PencilFill, PersonCircle, PersonLinesFill, Trash3Fill } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import axios from "axios";

function Students() {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    fetchStudentsData();
  }, []);

  function fetchStudentsData() {
    let user_info = JSON.parse(localStorage.getItem('user-info'));
    if (user_info) {
      let formData = new FormData();
      formData.append("campusId", 1);
      formData.append("api_token", user_info.token);

      const config = {
        headers: {
          'Authorization': `Bearer ${user_info.token}`,
          'Content-Type': 'multipart/form-data'
        }
      };

      axios.post('https://lihsorphanages.com/lihs-app/public/index.php/api/getStudentByCampus', formData, config)
          .then(response => {
            if (response.data.status === 200) {
              console.log(response.data)
              setRows(response.data.data)
            } else {
              console.log(response.data.message);
            }
          })
          .catch(e => {
            console.log(e);
          });
    }
  }

  return (
      <Container style={{ backgroundColor: "#f3f3f3" }} fluid>
        <Row>
          <Navbar />
          <Col style={{ padding: 20, paddingLeft: 30, paddingRight: 30 }}>
            <Row>
              <Col>
                <Row>
                  <Col>
                    <p style={{ marginBottom: -4, fontSize: 14, fontWeight: "400" }}>
                      Dashboard / Students
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p style={{ fontSize: 24, fontWeight: "600" }}>Students</p>
                  </Col>
                </Row>
              </Col>
              <Col xs={6}>
                <Row className="justify-content-end">
                  <Col
                      xs={6}
                      style={{
                        backgroundColor: "white",
                        borderRadius: 100,
                        border: "1px solid",
                        borderColor: "#e5e5e5",
                      }}
                  >
                    <Row style={{ paddingTop: 5, paddingBottom: 5 }}>
                      <Col xs={6}>
                        <Form.Group className="w-100">
                          <Form.Control
                              style={{ borderRadius: 100, backgroundColor: "#f3f3f3" }}
                              placeholder="search"
                          />
                        </Form.Group>
                      </Col>
                      <Col
                          xs={2}
                          className="d-flex align-items-center justify-content-center"
                      >
                        <Bell />
                      </Col>
                      <Col
                          xs={2}
                          className="d-flex align-items-center justify-content-center"
                      >
                        <Bell />
                      </Col>
                      <Col
                          xs={2}
                          className="d-flex align-items-center justify-content-center"
                      >
                        <PersonCircle size="25px" />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row
                style={{
                  padding: 10,
                  backgroundColor: "white",
                  borderRadius: 10,
                  height: "82vh",
                }}
            >
              <Row style={{ padding: 10 }}>
                <Col>
                  <h4>Students List</h4>
                </Col>
                <Col className="d-flex justify-content-end" style={{ padding: 0 }}>
                  <Link to={"/newregisteration"} style={{ textDecoration: "none", color: "black" }}>
                    <Button variant="primary">New +</Button>
                  </Link>
                </Col>
              </Row>
              <Table size="sm" hover striped>
                <thead>
                <tr>
                  <th>Sr. No.</th>
                  <th colSpan={2}>Name</th>
                  <th>Age</th>
                  <th>Class</th>
                  <th>Campus</th>
                  <th className="d-flex justify-content-center">Details</th>
                </tr>
                </thead>
                <tbody>
                {rows.map((row, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td colSpan={2}>{row.name}</td>
                      <td>{row.age}</td>
                      <td>{row.class}</td>
                      <td>{row.campus.name}</td>
                      <td className="d-flex justify-content-center">
                        <ButtonGroup>
                          <Button href={`/studentProfile/${row.id}`} variant="primary">
                            <PersonLinesFill />
                          </Button>
                          <Button variant="secondary">
                            <PencilFill />
                          </Button>
                          <Button variant="danger">
                            <Trash3Fill />
                          </Button>
                        </ButtonGroup>
                      </td>
                    </tr>
                ))}
                </tbody>
              </Table>
            </Row>
          </Col>
        </Row>
      </Container>
  );
}

export default Students;
