import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";

export default function GuardianInfo({formData, updateFormData}) {
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    updateFormData({ ...formData, [name]: value });
  };
  return (
    <Row style={{ backgroundColor: "white", padding: 10 }}>
      <Row
        style={{
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
          borderBottom: "1px solid lightgrey",
          padding: 5,
          marginBottom: 10,
        }}
      >
        <h5>Guardian Information</h5>
      </Row>
      <Row style={{ padding: 2 }}>
        <Col>
          <Form.Group controlId="">
            <Form.Label>Guardian Name</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="guardianName"
                value={formData.guardianName}
              onChange={handleInputChange}
              placeholder="Mother Name"
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="">
            <Form.Label>CNIC No.</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="guardianCnicNo"
                value={formData.guardianCnicNo}
              onChange={handleInputChange}
              placeholder="CNIC No."
            />
          </Form.Group>
        </Col>
      </Row>
      <Row style={{ padding: 2 }}>
        <Col>
          <Form.Group controlId="">
            <Form.Label>Relation with Child</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="guardianRelation"
                value={formData.guardianRelation}
              onChange={handleInputChange}
              placeholder="Relation with Child"
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="">
            <Form.Label>Occupation</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="guardianOccupation"
                value={formData.guardianOccupation}
              onChange={handleInputChange}
              placeholder="Occupation"
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="">
            <Form.Label>Monthly Income</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="guardianMonthlyIncome"
                value={formData.guardianMonthlyIncome}
              onChange={handleInputChange}
              placeholder="Monthly Income"
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="">
            <Form.Label>Education</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="guardianEducation"
                value={formData.guardianEducation}
              onChange={handleInputChange}
              placeholder="Education"
            />
          </Form.Group>
        </Col>
      </Row>

      <Row style={{ padding: 2 }}>
        <Col xs={6}>
          <Form.Group controlId="">
            <Form.Label>Institute Name</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="guardianInstituteName"
                value={formData.guardianInstituteName}
              onChange={handleInputChange}
              placeholder="Institute Name"
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="">
            <Form.Label>Position</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="guardianDesignation"
                value={formData.guardianDesignation}
              onChange={handleInputChange}
              placeholder="Position"
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="">
            <Form.Label>Mobile No.</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="guardianMobileNo"
                value={formData.guardianMobileNo}
              onChange={handleInputChange}
              placeholder="Mobile No."
            />
          </Form.Group>
        </Col>
      </Row>
      <Row style={{ padding: 2 }}>
        <Col>
          <Form.Group controlId="">
            <Form.Label>Phone No.</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="guardianTelephone"
                value={formData.guardianTelephone}
              onChange={handleInputChange}
              placeholder="Phone No."
            />
          </Form.Group>
        </Col>
        <Col xs={9}>
          <Form.Group controlId="">
            <Form.Label>Present Address</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="guardianPresentAddress"
                value={formData.guardianPresentAddress}
              onChange={handleInputChange}
              placeholder="Present Address"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row style={{ padding: 2 }}>
        <Col>
          <Form.Group controlId="">
            <Form.Label>Permanent Address</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="guardianPermanentAddress"
                value={formData.guardianPermanentAddress}
              onChange={handleInputChange}
              placeholder="Permanent Address"
            />
          </Form.Group>
        </Col>
      </Row>
    </Row>
  );
}
