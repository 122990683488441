import React from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import Navbar from "../Components/Navbar";
import ExpenseChart from "../Components/ExpenseChart";
import {
  BarChartFill,
  Bell,
  CaretUpFill,
  CheckCircleFill,
  PeopleFill,
  PersonCircle,
  TrophyFill,
} from "react-bootstrap-icons"; 

function Dashboard() {
  return (
    <Container className="vh-100" style={{ backgroundColor: "#f3f3f3" }} fluid>
      <Row>
        <Navbar />
        <Col style={{ padding: 20, paddingLeft: 30, paddingRight: 30 }}>
          <Row style={{ borderBottom: "1px solid black" }}>
            <Col>
              <Row>
                <Col>
                  <p
                    style={{
                      marginBottom: -4,
                      fontSize: 14,
                      fontWeight: "400",
                    }}
                  >
                    Dashboard
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p style={{ fontSize: 24, fontWeight: "600" }}>
                    Main Dashboard
                  </p>
                </Col>
              </Row>
            </Col>
            <Col xs={6}>
              <Row className="justify-content-end">
                <Col
                  xs={6}
                  style={{
                    backgroundColor: "white",
                    borderRadius: 100,
                    border: "1px solid",
                    borderColor: "#e5e5e5",
                  }}
                >
                  <Row style={{ paddingTop: 5, paddingBottom: 5 }}>
                    <Col xs={6}>
                      <Form.Group className="w-100">
                        <Form.Control
                          style={{
                            borderRadius: 100,
                            backgroundColor: "#f3f3f3",
                          }}
                          placeholder="search"
                        />
                      </Form.Group>
                    </Col>
                    <Col
                      xs={2}
                      className="d-flex align-items-center justify-content-center"
                    >
                      <Bell />
                    </Col>
                    <Col
                      xs={2}
                      className="d-flex align-items-center justify-content-center"
                    >
                      <Bell />
                    </Col>
                    <Col
                      xs={2}
                      className="d-flex align-items-center justify-content-center"
                    >
                      <PersonCircle size="25px" />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={4}>
              <Row style={{ padding: 10 }}>
                <Col
                  className="d-flex align-items-center justify-content-center"
                  style={{
                    backgroundColor: "white",
                    margin: 3,
                    marginBottom: 0,
                    marginRight: 5,
                    borderRadius: 10,
                    padding: 5,
                    border: "1px solid #e5e5e5",
                  }}
                >
                  <Row className="d-flex align-items-center justify-content-center text-center">
                    <Row
                      className="d-flex align-items-center justify-content-center mt-2"
                      style={{
                        backgroundColor: "#f3f3f3",
                        borderRadius: "100%",
                        height: 70,
                        width: 70,
                      }}
                    >
                      <BarChartFill size={30} />
                    </Row>
                    <Row className="d-flex align-items-center text-center justify-content-center mt-1">
                      <p style={{ fontWeight: "500", margin: 0 }}>Admissions</p>
                    </Row>
                    <Row className="d-flex align-items-center text-center justify-content-center">
                      <h1
                        style={{ fontWeight: "bold", fontSize: 45, margin: 0 }}
                      >
                        04
                      </h1>
                    </Row>
                    <Row
                      className="d-flex align-items-center text-center justify-content-center mt-1 mb-1"
                      // style={{ backgroundColor: "white" }}
                    >
                      <Col
                        xs={10}
                        style={{
                          backgroundColor: "#f3f3f3",
                          borderRadius: 5,
                          padding: 5,
                        }}
                      >
                        <p
                          style={{
                            fontWeight: "normal",
                            fontSize: 16,
                            margin: 0,
                            color: "grey",
                          }}
                        >
                          This Month
                        </p>
                      </Col>
                    </Row>
                  </Row>
                </Col>
                <Col
                  className="d-flex align-items-center justify-content-center"
                  style={{
                    backgroundColor: "white",
                    margin: 3,
                    marginBottom: 0,
                    marginLeft: 5,
                    borderRadius: 10,
                    padding: 5,
                    border: "1px solid #e5e5e5",
                  }}
                >
                  <Row className="d-flex align-items-center justify-content-center text-center">
                    <Row
                      className="d-flex align-items-center justify-content-center mt-2"
                      style={{
                        backgroundColor: "#f3f3f3",
                        borderRadius: "100%",
                        height: 70,
                        width: 70,
                      }}
                    >
                      <TrophyFill size={30}/>
                    </Row>
                    <Row className="d-flex align-items-center text-center justify-content-center mt-1">
                      <p style={{ fontWeight: "500", margin: 0 }}>
                        Drop/Passout
                      </p>
                    </Row>
                    <Row className="d-flex align-items-center text-center justify-content-center">
                      <h1
                        style={{ fontWeight: "bold", fontSize: 45, margin: 0 }}
                      >
                        01
                      </h1>
                    </Row>
                    <Row
                      className="d-flex align-items-center text-center justify-content-center mt-1 mb-1"
                      // style={{ backgroundColor: "white" }}
                    >
                      <Col
                        xs={10}
                        style={{
                          backgroundColor: "#f3f3f3",
                          borderRadius: 5,
                          padding: 5,
                        }}
                      >
                        <p
                          style={{
                            fontWeight: "normal",
                            fontSize: 16,
                            margin: 0,
                            color: "grey",
                          }}
                        >
                          This Month
                        </p>
                      </Col>
                    </Row>
                  </Row>
                </Col>
              </Row>
              {/* <Row style={{ padding: 10 }}>
                <Col
                  className="d-flex align-items-center justify-content-center"
                  style={{
                    backgroundColor: "white",
                    borderRadius: 10,
                    padding: 5,
                    border: "1px solid #e5e5e5",
                  }}
                >
                  <Container as={Row} fluid>
                    <Col
                      xs={3}
                      className="d-flex align-items-center justify-content-start mt-2 mb-2"
                    >
                      <Row
                        className="d-flex align-items-center justify-content-center"
                        style={{
                          backgroundColor: "#f3f3f3",
                          borderRadius: "100%",
                          height: 60,
                          width: 60,
                        }}
                      >
                        <Bell />
                      </Row>
                    </Col>
                    <Col xs={9} className="mt-2 mb-2">
                      <Row className="d-flex align-items-center justify-content-center text-center">
                        <p style={{ fontWeight: "500", margin: 0 }}>
                          Expense (This Month)
                        </p>
                      </Row>
                      <Row className="d-flex align-items-center justify-content-center text-center">
                        <h1
                          style={{
                            fontWeight: "bold",
                            fontSize: 30,
                            margin: 0,
                          }}
                        >
                          Rs. 10,000
                        </h1>
                      </Row>
                    </Col>
                  </Container>
                </Col>
              </Row> */}
            </Col>
            <Col xs={8}>
              <Row style={{ padding: 10, paddingLeft:0 }}>
                <Col
                  className="d-flex align-items-center justify-content-center"
                  style={{
                    backgroundColor: "white",
                    margin: 3,
                    marginBottom: 0,
                    marginRight: 5,
                    borderRadius: 10,
                    padding: 5,
                    border: "1px solid #e5e5e5",
                  }}
                >
                  <Row className="d-flex align-items-center justify-content-center text-center">
                    <Row
                      className="d-flex align-items-center justify-content-center mt-2"
                      style={{
                        backgroundColor: "#f3f3f3",
                        borderRadius: "100%",
                        height: 70,
                        width: 70,
                      }}
                    >
                      <PeopleFill size={30} />
                    </Row>
                    <Row className="d-flex align-items-center text-center justify-content-center mt-1">
                      <p style={{ fontWeight: "500", margin: 0 }}>No. of Children</p>
                    </Row>
                    <Row className="d-flex align-items-center text-center justify-content-center">
                      <h1
                        style={{ fontWeight: "bold", fontSize: 45, margin: 0 }}
                      >
                        63
                      </h1>
                    </Row>
                    <Row
                      className="d-flex align-items-center text-center justify-content-center mt-1 mb-1"
                      // style={{ backgroundColor: "white" }}
                    >
                      <Col
                        xs={10}
                        style={{
                          backgroundColor: "#f3f3f3",
                          borderRadius: 5,
                          padding: 5,
                        }}
                      >
                        <p
                          style={{
                            fontWeight: "normal",
                            fontSize: 16,
                            margin: 0,
                            color: "grey",
                          }}
                        >
                          MCH LHR
                        </p>
                      </Col>
                    </Row>
                  </Row>
                </Col>
                <Col
                  className="d-flex align-items-center justify-content-center"
                  style={{
                    backgroundColor: "white",
                    margin: 3,
                    marginBottom: 0,
                    marginLeft: 5,
                    borderRadius: 10,
                    padding: 5,
                    border: "1px solid #e5e5e5",
                  }}
                >
                  <Row className="d-flex align-items-center justify-content-center text-center">
                    <Row
                      className="d-flex align-items-center justify-content-center mt-2"
                      style={{
                        backgroundColor: "#f3f3f3",
                        borderRadius: "100%",
                        height: 70,
                        width: 70,
                      }}
                    >
                      <PeopleFill size={30} />
                    </Row>
                    <Row className="d-flex align-items-center text-center justify-content-center mt-1">
                      <p style={{ fontWeight: "500", margin: 0 }}>
                        No. of Children
                      </p>
                    </Row>
                    <Row className="d-flex align-items-center text-center justify-content-center">
                      <h1
                        style={{ fontWeight: "bold", fontSize: 45, margin: 0 }}
                      >
                        44
                      </h1>
                    </Row>
                    <Row
                      className="d-flex align-items-center text-center justify-content-center mt-1 mb-1"
                      // style={{ backgroundColor: "white" }}
                    >
                      <Col
                        xs={10}
                        style={{
                          backgroundColor: "#f3f3f3",
                          borderRadius: 5,
                          padding: 5,
                        }}
                      >
                        <p
                          style={{
                            fontWeight: "normal",
                            fontSize: 16,
                            margin: 0,
                            color: "grey",
                          }}
                        >
                          Kashana LHR
                        </p>
                      </Col>
                    </Row>
                  </Row>
                </Col>
                <Col
                  className="d-flex align-items-center justify-content-center"
                  style={{
                    backgroundColor: "white",
                    margin: 3,
                    marginBottom: 0,
                    marginLeft: 5,
                    borderRadius: 10,
                    padding: 5,
                    border: "1px solid #e5e5e5",
                  }}
                >
                  <Row className="d-flex align-items-center justify-content-center text-center">
                    <Row
                      className="d-flex align-items-center justify-content-center mt-2"
                      style={{
                        backgroundColor: "#f3f3f3",
                        borderRadius: "100%",
                        height: 70,
                        width: 70,
                      }}
                    >
                      <PeopleFill size={30} />
                    </Row>
                    <Row className="d-flex align-items-center text-center justify-content-center mt-1">
                      <p style={{ fontWeight: "500", margin: 0 }}>
                        Total Children
                      </p>
                    </Row>
                    <Row className="d-flex align-items-center text-center justify-content-center">
                      <h1
                        style={{ fontWeight: "bold", fontSize: 45, margin: 0 }}
                      >
                        107
                      </h1>
                    </Row>
                    <Row
                      className="d-flex align-items-center text-center justify-content-center mt-1 mb-1"
                      // style={{ backgroundColor: "white" }}
                    >
                      <Col
                        xs={10}
                        style={{
                          backgroundColor: "#f3f3f3",
                          borderRadius: 5,
                          padding: 5,
                        }}
                      >
                        <p
                          style={{
                            fontWeight: "normal",
                            fontSize: 16,
                            margin: 0,
                            color: "grey",
                          }}
                        >
                          LIHS
                        </p>
                      </Col>
                    </Row>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default Dashboard;
