import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Bell, PersonCircle } from "react-bootstrap-icons";
import Navbar from "../Components/Navbar";
import MultiStepForm from "../Components/MultiStepForm";

function RegisterationForm() {
  return (
    <Container className="vh-100" style={{ backgroundColor: "#f3f3f3" }} fluid>
      <Row>
        <Navbar />
        <Col
          style={{
                        padding: 10,
          }}
        >
          <Container fuild>
            <Row style={{ backgroundColor: "#f3f3f3", padding: 10 }}>
              <Row style={{ borderBottom: "1px solid black", marginBottom:10 }}>
                <Col>
                  <Row>
                    <Col>
                      <p
                        style={{
                          marginBottom: -4,
                          fontSize: 14,
                          fontWeight: "400",
                        }}
                      >
                        Dashboard / Admissions
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p style={{ fontSize: 24, fontWeight: "600" }}>
                        New Admission
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col xs={6}>
                  <Row className="justify-content-end">
                    <Col
                      xs={6}
                      style={{
                        backgroundColor: "white",
                        borderRadius: 100,
                        border: "1px solid",
                        borderColor: "#e5e5e5",
                      }}
                    >
                      <Row style={{ paddingTop: 5, paddingBottom: 5 }}>
                        <Col xs={6}>
                          <Form.Group className="w-100">
                            <Form.Control
                              style={{
                                borderRadius: 100,
                                backgroundColor: "#f3f3f3",
                              }}
                              placeholder="search"
                            />
                          </Form.Group>
                        </Col>
                        <Col
                          xs={2}
                          className="d-flex align-items-center justify-content-center"
                        >
                          <Bell />
                        </Col>
                        <Col
                          xs={2}
                          className="d-flex align-items-center justify-content-center"
                        >
                          <Bell />
                        </Col>
                        <Col
                          xs={2}
                          className="d-flex align-items-center justify-content-center"
                        >
                          <PersonCircle size="25px" />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <MultiStepForm />
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
}

export default RegisterationForm;
