import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";

export default function SchoolInfo({formData, updateFormData}) {
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    updateFormData({ ...formData, [name]: value });
  };

  return (
    <Row style={{ backgroundColor: "white", padding: 10 }}>
      <Row
        style={{
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
          borderBottom: "1px solid lightgrey",
          padding: 5,
          marginBottom: 10,
        }}
      >
        <h5>School Information</h5>
      </Row>
      <Row style={{ padding: 2 }}>
        <Col xs={8}>
          <Form.Group controlId="">
            <Form.Label>School Name</Form.Label>
            <Form.Control
              type="text"
              name="schoolName"
                value={formData.schoolName}
              onChange={handleInputChange}
              placeholder="School Name"
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="">
            <Form.Label>Date of Admission</Form.Label>
            <Form.Control
              type="date"
              name="schoolAdmissionDate"
                value={formData.schoolAdmissionDate}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row style={{ padding: 2 }}>
        <Col>
          <Form.Group controlId="">
            <Form.Label>Admission No.</Form.Label>
            <Form.Control
              type="text"
              name="schoolAdmissionNo"
                value={formData.schoolAdmissionNo}
              onChange={handleInputChange}
              placeholder="Admission No."
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="">
            <Form.Label>Class</Form.Label>
            <Form.Control
              type="text"
              name="class"
                value={formData.class}
              onChange={handleInputChange}
              placeholder="Class"
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="">
            <Form.Label>Session</Form.Label>
            <Form.Control
              type="text"
              name="session"
                value={formData.session}
              onChange={handleInputChange}
              placeholder="Session"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row style={{ padding: 2 }}>
        <Col>
          <Form.Group controlId="">
            <Form.Label>School Address</Form.Label>
            <Form.Control
              type="text"
              name="schoolAddress"
                value={formData.schoolAddress}
              onChange={handleInputChange}
              placeholder="School Address"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row style={{ padding: 2 }}>
        <Col xs={3}>
          <Form.Group controlId="">
            <Form.Label>School Phone No.</Form.Label>
            <Form.Control
              type="text"
              name="schoolTelephone"
                value={formData.schoolTelephone}
              onChange={handleInputChange}
              placeholder="Phone No."
            />
          </Form.Group>
        </Col>
        <Col xs={3}>
          <Form.Group controlId="">
            <Form.Label>Mobile No.</Form.Label>
            <Form.Control
              type="text"
              name="schoolMobileNo"
                value={formData.schoolMobileNo}
              onChange={handleInputChange}
              placeholder="Mobile No."
            />
          </Form.Group>
        </Col>
        <Col xs={2}>
          <Form.Group controlId="">
            <Form.Label>Type of School</Form.Label>
            {/*{["checkbox"].map((type) => (*/}
            {/*  <div key={`inline-${type}`} className="mb-3">*/}
            {/*    <Form.Check*/}
            {/*      inline*/}
            {/*      label="Govt."*/}
            {/*      name="group1"*/}
            {/*      type={type}*/}
            {/*      id={`inline-${type}-1`}*/}
            {/*    />*/}
            {/*    <Form.Check*/}
            {/*      inline*/}
            {/*      label="Private"*/}
            {/*      name="group1"*/}
            {/*      type={type}*/}
            {/*      id={`inline-${type}-2`}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*))}*/}
            <div key={`inline-radio`} className="mb-3">
              <Form.Check
                  inline
                  label="Govt."
                  name="isGovt"
                  type="radio"
                  id={`inline-radio-1`}
                  value="1"
                  checked={formData.isGovt == 1} // Check against 1
                  onChange={handleInputChange}
              />
              <Form.Check
                  inline
                  label="Private"
                  name="isGovt"
                  type="radio"
                  id={`inline-radio-2`}
                  value="0"
                  checked={formData.isGovt == 0} // Check against 0
                  onChange={handleInputChange}
              />
            </div>
          </Form.Group>
        </Col>

        <Col>
          <Form.Group controlId="">
            <Form.Label>Monthly Fee</Form.Label>
            <Form.Control
              type="text"
              name="schoolMonthlyFee"
                value={formData.schoolMonthlyFee}
              onChange={handleInputChange}
              placeholder="Monthly Fee."
            />
          </Form.Group>
        </Col>
      </Row>
      <Row style={{ padding: 2 }}>
        <Col xs={6}>
          <Form.Group controlId="">
            <Form.Label>Headmaster Name</Form.Label>
            <Form.Control
              type="text"
              name="headName"
                value={formData.headName}
              onChange={handleInputChange}
              placeholder="Headmaster Name"
            />
          </Form.Group>
        </Col>
        <Col style={{ justifyContent: "center" }}>
          <Form.Group controlId="">
            <Form.Label>
              Headmaster is an employee or owner of the school?
            </Form.Label>
            {/*{["checkbox"].map((type) => (*/}
            {/*  <div key={`inline-${type}`} className="mb-3">*/}
            {/*    <Form.Check*/}
            {/*      inline*/}
            {/*      label="Owner"*/}
            {/*      name="group1"*/}
            {/*      type={type}*/}
            {/*      id={`inline-${type}-1`}*/}
            {/*    />*/}
            {/*    <Form.Check*/}
            {/*      inline*/}
            {/*      label="Employee"*/}
            {/*      name="group1"*/}
            {/*      type={type}*/}
            {/*      id={`inline-${type}-2`}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*))}*/}
            <div key={`inline-radio-status`} className="mb-3">
              <Form.Check
                  inline
                  label="Owner"
                  name="headDesignation"
                  type="radio"
                  id={`inline-radio-status-1`}
                  value="Owner"
                  checked={formData.headDesignation == 'Owner'}
                  onChange={handleInputChange}
              />
              <Form.Check
                  inline
                  label="Employee"
                  name="headDesignation"
                  type="radio"
                  id={`inline-radio-status-2`}
                  value="Employee"
                  checked={formData.headDesignation == 'Employee'}
                  onChange={handleInputChange}
              />
            </div>
          </Form.Group>
        </Col>
      </Row>
    </Row>
  );
}
